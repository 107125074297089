<template>
  <div id="form">
    <div style="position: absolute;margin: 30px; color: white; z-index: 1000;">
      <span style="font-size: 20px">您选择的是</span>
      <span style="font-size: 36px"
        ><el-divider direction="vertical"></el-divider
        >{{ this.$route.query.groupName || '未知页面' }}</span
      >
    </div>
    <full-page ref="fullpage" :options="options">
      <div
        v-for="(item, index) in formList"
        v-bind:key="item.id"
        class="section"
      >
        <div :class="item.id % 2 === 0 ? 'content' : 'content-reverse'">
          <div v-if="item.type === 'checkbox'">
            <checkbox
              :id="index + 1"
              :description="item.description"
              :has-next="item.id !== formList.length - 1"
              :max="item.max"
              :min="item.min"
              :options="item.options"
              :result.sync="item.result"
              :title="item.title"
            />
          </div>
          <div v-if="item.type === 'radio'">
            <radio
              :id="index + 1"
              :description="item.description"
              :has-next="item.id !== formList.length - 1"
              :options="JSON.parse(item.options)"
              :result.sync="item.result"
              :title="item.title"
            />
          </div>
          <div v-if="item.type === 'input'">
            <text-input
              :id="index + 1"
              :description="item.description"
              :has-next="item.id !== formList.length - 1"
              :result.sync="item.result"
              :title="item.title"
              :validation="item.validation ? item.validation : ''"
            />
          </div>
          <div style="align-items: end; justify-content: end">
            <el-button
              v-if="index !== formList.length - 1"
              circle
              class="button"
              icon="el-icon-bottom"
              @click="onNext(item)"
            >
            </el-button>
            <el-button
              v-if="index === formList.length - 1"
              circle
              class="submit"
              icon="el-icon-check"
              size="large"
              @click="submit"
            >
            </el-button>
          </div>
        </div>
      </div>
    </full-page>
    <div class="progress">
      <div style="display: flex;flex-direction: row-reverse;width: 100%">
        <div class="previous" @click="onPrev">
          <span
            ><i class="el-icon-arrow-up" style="padding-right: 10px"></i
            >返回上一个问题</span
          >
        </div>
      </div>
      <el-progress
        :percentage="percentage"
        :show-text="false"
        color="#4C12A1"
      ></el-progress>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/form/Checkbox';
import Radio from '@/components/form/Radio';
import TextInput from '@/components/form/TextInput';

export default {
  components: {
    TextInput,
    Radio,
    Checkbox,
  },
  data() {
    const data = {
      options: {
        onLeave: this.onLeave,
      },
      pageTitle: '',
      percentage: 0,
    };
    data.formList = this.forms;
    return data;
  },
  props: ['title', 'forms'],
  methods: {
    submit() {
      console.log(this.formList);
      this.axios
        .post('/form/submitFormAnswers', {
          formjson: JSON.stringify(this.formList),
          userid: localStorage.getItem('userId'),
          username: localStorage.getItem('userName'),
          phone: localStorage.getItem('userPhone'),
          groupid: this.$route.query.id,
        })
        .then((response) => {
          console.log(response);
          this.$router.push('/thanks');
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onLeave(index, nextIndex) {
      if (nextIndex.isLast) {
        this.percentage = 100;
      } else {
        this.percentage = this.step * (nextIndex.index + 1);
      }
    },
    onNext(item) {
      if (item.result) {
        this.$refs.fullpage.api.moveSectionDown();
      } else {
        this.$message.warning('请填写当前表单');
      }
    },
    onPrev() {
      this.$refs.fullpage.api.moveSectionUp();
    },
  },
  mounted() {
    this.step = Math.round(100 / this.formList.length);
    this.percentage = this.step;
    this.$refs.fullpage.api.setAllowScrolling(false);
    this.pageTitle = this.$route.query.groupName;
  },
};
</script>

<style scoped>
.content {
  display: flex;
  width: 100vw;
  height: 100vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(#8a75d1, #a7c6ed);
}

.content-reverse {
  display: flex;
  width: 100vw;
  height: 100vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(#a7c6ed, #8a75d1);
}

.button {
  margin-left: 5vw;
  margin-top: 2vh;
  box-shadow: 0 0 20px #a7c6ed;
}

.button:hover {
  margin-left: 5vw;
  margin-top: 2vh;
  box-shadow: 0 0 40px #a7c6ed;
}

.submit {
  margin-left: 5vw;
  margin-top: 2vh;
  box-shadow: 0 0 20px #a7c6ed;
}

.submit:hover {
  margin-left: 5vw;
  margin-top: 2vh;
  box-shadow: 0 0 50px #ffffff;
}

.progress {
  width: 90vw;
  bottom: 4vh;
  margin-left: 5vw;
  margin-right: 5vw;
  position: fixed;
  z-index: 5555;
}

.previous {
  z-index: 999;
  margin-bottom: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  font-size: 18px;
  border: solid 1px white;
  border-radius: 3px;
  transition: ease-in-out 0.3s;
}

.previous:hover {
  background: rgba(255, 255, 255, 0.3);
  color: #4d4ea5;
  border: solid 1px #4d4ea5;
}
</style>
