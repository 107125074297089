<template>
  <div id="form">
    <link
      href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1"
      rel="stylesheet"
      type="text/css"
    />
    <h3 class="text">
      <i class="el-icon-right arrow"></i>
      <span class="id">{{ id }}</span
      >{{ title }}
    </h3>
    <p>{{ description }}</p>
    <el-divider></el-divider>
    <el-checkbox-group :min="min" :max="max" v-model="result">
      <el-checkbox
        v-for="option in options"
        :key="option"
        :label="option"
        @change="update"
      >
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    options: {
      type: Array,
    },
    hasNext: {
      type: Boolean,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    id: {
      type: Number,
    },
  },
  data() {
    return {
      result: [],
    };
  },
  methods: {
    update() {
      this.$emit('update:result', this.result);
    },
  },
};
</script>

<style scoped>
#form {
  position: relative;
  align-items: start;
  justify-content: start;
  text-align: left;
  width: 40vw;
  border-right: #d3dce6 1px solid;
  color: white;
}

.id {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: lighter;
}

.el-checkbox {
  color: white;
}

.arrow {
  animation: wiggle 2.5s infinite ease-in-out;
}

@keyframes wiggle {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-20px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
</style>
