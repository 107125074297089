<template>
  <div id="form">
    <h3>
      <i class="el-icon-right arrow"></i>
      <span class="id">{{ id }}</span
      >{{ title }}
    </h3>
    <p>{{ description }}</p>
    <el-divider></el-divider>
    <el-radio-group v-model="result">
      <el-radio v-for="option in options" :key="option" :label="option" @change="update">
        {{ option }}
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    options: {
      type: Array,
    },
    hasNext: {
      type: Boolean,
    },
    id: {
      type: Number,
    },
  },
  data() {
    return {
      result: '',
    };
  },
  methods: {
    update() {
      this.$emit('update:result', this.result);
    },
  },
};
</script>

<style scoped>
#form {
  position: relative;
  align-items: start;
  justify-content: start;
  text-align: left;
  width: 40vw;
  border-right: #d3dce6 1px solid;
  color: white;
}

.id {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: lighter;
}

.el-radio {
  color: white;
}

.arrow {
  animation: wiggle 2.5s infinite ease-in-out;
}

@keyframes wiggle {
  0% { transform: translate(0px, 0px); }
  50% { transform: translate(-20px, 0px); }
  100% { transform: translate(0px, 0px);}
}
</style>
