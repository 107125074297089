<template>
  <div id="form">
    <h3>
      <i class="el-icon-right arrow"></i>
      <span class="id">{{ id }}</span> {{ title }}
    </h3>
    <el-divider></el-divider>
    <el-popover
      placement="bottom"
      title="输入有误"
      width="200"
      trigger="manual"
      :content="errorMsg"
      v-model="visible"
    >
      <el-input
        slot="reference"
        v-model="result"
        :placeholder="placeholder"
        style="width: 38vw"
        @input="update"
      ></el-input>
    </el-popover>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    hasNext: {
      type: Boolean,
    },
    id: {
      type: Number,
    },
    validation: {
      type: String,
    },
  },
  data() {
    return {
      result: '',
      errorMsg: '',
      visible: false,
    };
  },
  methods: {
    update() {
      this.$emit('update:result', this.result);
      this.validate(this.result);
    },
    validate(content) {
      switch (this.validation) {
        case 'email':
          if (!content.match(/^1(3|4|5|6|7|8|9)\d{9}$/g)) {
            this.visible = true;
            this.errorMsg = '请填写正确的邮箱格式，如：test@163.com';
          } else {
            this.visible = false;
          }
          break;
        case 'phone':
          if (!content.match(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/g) || !content.match(/(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}/g)) {
            this.visible = true;
            this.errorMsg = '请添加正确的电话';
          } else {
            this.visible = false;
          }
          break;
        default:
          break;
      }
    },
  },
  computed: {
    placeholder() {
      if (this.description) {
        return `请输入内容，如：${this.description}`;
      }
      return '请输入内容';
    },
  },
};
</script>

<style scoped>
#form {
  position: relative;
  align-items: start;
  justify-content: start;
  text-align: left;
  width: 40vw;
  border-right: #d3dce6 1px solid;
  color: white;
}

.id {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: lighter;
}

.el-input >>> .el-input__inner {
  background-color: #2f2a95;
  border-color: white;
  color: white;
  opacity: 0.7;
}

.el-input__inner::placeholder {
  color: #fff;
  opacity: 0;
  text-align: center;
}

.arrow {
  animation: wiggle 2.5s infinite ease-in-out;
}

@keyframes wiggle {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-20px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
</style>
